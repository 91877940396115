import { Component, OnInit } from '@angular/core';
import { LoadingController, NavParams, NavController, AlertController, ModalController, ToastController } from '@ionic/angular';
import { AllDataService } from '../../services/all-data/all-data.service'
@Component({
  selector: 'app-formulario-detalhes',
  templateUrl: './formulario-detalhes.page.html',
  styleUrls: ['./formulario-detalhes.page.scss'],
})
export class FormularioDetalhesPage implements OnInit {
  formularioObj: any;
  usuario: any;
  idAtual: any;
  constructor(
    private navParams: NavParams,
    public allData: AllDataService,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    public modalController: ModalController,
    public alertController: AlertController,
  ) {
    this.formularioObj = this.navParams.data['form']
    this.usuario = this.navParams.data['usuario']
    console.log(this.navParams.data)
   }

  ngOnInit() {
  }
  fechar() {
    this.modalController.dismiss();
  }
}
